import React from "react";
import { Spinner } from "../Spinner";

type SpinnerProps = React.ComponentProps<typeof Spinner>;

/** Uses Spinner `props` to calculate its' params */
export function useController(input: SpinnerProps) {
  return {
    variant: getVariant(),
  };

  /** Calculates variant based on its props */
  function getVariant() {
    return variantMap[input.variant];
  }
}

const variantMap = {
  default: "primary",
  error: "error",
  info: "info",
  success: "success",
} as const;
