import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createTRPCReact, httpBatchLink } from "@trpc/react-query";
import type { AppRouter } from "backend/src";
import { EnvService } from "src/services/EnvService";
import { useHeaders } from "./useHeaders";
export type { RouterInputs, RouterOutputs } from "backend/src";

const backendUrlMap = {
  PRODUCTION: "https://corporate-onboarding-bff.us.flashapp.services/bff/trpc",
  STAGING: "https://corporate-onboarding-bff.private.flashapp.dev/bff/trpc",
  LOCAL: "http://localhost:3000/trpc",
} as const;
const backendUrl = backendUrlMap[EnvService.APP_STAGE];

export const trpc = createTRPCReact<AppRouter>();

/** Wraps the app with TRPC and React Query's context providers */
export function TRPCProvider({ children }: { children: React.ReactNode }) {
  const headers = useHeaders();
  const queryClient = new QueryClient();
  const trpcClient = trpc.createClient({
    links: [
      httpBatchLink({
        url: backendUrl,
        headers,
      }),
    ],
  });

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  );
}
