import styled from "styled-components";
import React from "react";

/** A horizontal or vertical gray line, used as a divider */
export function Divider(props: Props) {
  return <StyledDivider {...props} />;
}

type Props = {
  direction: "horizontal" | "vertical";
};

const StyledDivider = styled.div<{ direction: "horizontal" | "vertical" }>`
  width: ${(p) => (p.direction === "horizontal" ? "100%" : "1px")};
  height: ${(p) => (p.direction === "horizontal" ? "1px" : "100%")};
  min-height: ${(p) => (p.direction === "horizontal" ? "unset" : "100%")};
  background-color: ${(p) => p.theme.colors.neutral[90]};
`;
