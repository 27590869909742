import { Accordion as UIAccordion } from "@flash-tecnologia/hros-web-ui-v2";
import React from "react";
import { Typography } from "../../atoms/Typography/Typography";
import { useController } from "./hooks/useController";
import { Icon } from "../../atoms/Icon/Icon";
import { Flex } from "../../atoms/Flex/Flex";

/** Accordion component.
 * Displays a permanent section with `title` and `description`, and a
 * collapsible `children` section
 */
export function Accordion(props: Props) {
  const controller = useController(props);

  return (
    <UIAccordion
      variant="soft"
      expanded={props.isOpen}
      onChange={(_, open) => {
        props.onToggle?.(open);
      }}
      title={<Header {...props} controller={controller} />}
      fullWidth={props.fullWidth}
      disabled={props.disabled}
      actions={controller.rightActions}
    >
      {props.children}
    </UIAccordion>
  );
}

/** Header for the permanent section of the Accordion */
function Header(
  props: Props & { controller: ReturnType<typeof useController> },
) {
  return (
    <Flex gap="xs">
      <Icon
        name={props.isOpen ? "IconChevronDown" : "IconChevronUp"}
        size={16}
      />
      <Flex direction="column" align="start">
        <Typography.Headline9 color={props.controller.header.titleColor}>
          {props.title}
        </Typography.Headline9>
        {props.controller.header.descriptionVisible && props.description}
      </Flex>
    </Flex>
  );
}

type Props = {
  /** Content rendered inside a collapsible section */
  children: React.ReactNode;
  /** If true, hides the description when collapsed */
  collapsibleDescription?: boolean;
  /** Second line on the permanent section */
  description?: React.ReactNode;
  /** Prevents user interaction from opening the accordion */
  disabled?: boolean;
  /** Full width */
  fullWidth?: boolean;
  /** Sets the accordion open (controlled) */
  isOpen?: boolean;
  /** Called when the accordion is toggled open/closed */
  onToggle?: (expanded: boolean) => void;
  /** First line on the permanent section */
  title: string;
};
