import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import React from "react";
import { Variant } from "../../utils/variant";
import { useController } from "./hooks/useController";

/** Flash's SVG Icons */
export function Icon(props: Props) {
  const controller = useController(props);

  return <Icons name={props.name} size={props.size} color={controller.color} />;
}

/** Identifies the rendered icon */
export type IconName = React.ComponentProps<typeof Icons>["name"];

type Props = {
  /** Disables icon, overriding the color */
  disabled?: boolean;
  /** Defines the rendered icon */
  name: IconName;
  /** Icon size, in px */
  size: 12 | 16 | 24 | 32 | 40 | 48 | 56 | 64;
  /** Defines the icon color. Defaults to inheriting the parent color */
  variant?: Variant;
};
