import React from "react";
import { Divider } from "../../atoms/Divider/Divider";
import { Flex } from "../../atoms/Flex/Flex";

/** Renders a flat list, with dividers between items */
export function List(props: Props) {
  return (
    <Flex
      direction="column"
      radius="s"
      borderColor="neutral.90"
      borderWidth="xs"
    >
      {props.items.map((Item, index) => (
        <>
          {index !== 0 && <Divider direction="horizontal" />}
          {Item}
        </>
      ))}
    </Flex>
  );
}

type Props = {
  items: React.ReactNode[];
};
