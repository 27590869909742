import { Route } from "react-router-dom";
import { RouterService } from "src/services/RouterService";
import { FocusedFlows } from "./FocusedFlows";

export const ROUTE_FocusedFlows = RouterService.create(
  "corporate-onboarding/playground/focused-flows",
);

export const RouteComponent_FocusedFlows = (
  <Route index path={ROUTE_FocusedFlows.path} element={<FocusedFlows />} />
);
