import React from "react";
import { PillButton } from "../PillButton";
import { Variant } from "../../../utils/variant";
import { PillButton as UIPillButton } from "@flash-tecnologia/hros-web-ui-v2";

/** Uses PillButton `props` to calculate its' params */
export function useController(input: PillButtonProps) {
  return {
    size: getSize(),
    type: getType(),
    variant: getVariant(),
  } as const;

  /** Calculates the UI-PillButton size */
  function getSize() {
    return sizeMap[input.size];
  }

  /** Calculates the UI-PillButton filling type. Defaults to "outlined" */
  function getType() {
    if (input.type === "filled") return "primary";
    return "secondary";
  }

  /** Calculates the UI-PillButton variant */
  function getVariant() {
    return variantMap[input.variant];
  }
}

type PillButtonProps = React.ComponentProps<typeof PillButton>;
type UIPillButtonProps = React.ComponentProps<typeof UIPillButton>;

const variantMap = {
  default: "pink",
  error: "error",
  info: "info",
  neutral: "default",
  success: "success",
} satisfies Record<Variant, UIPillButtonProps["variant"]>;

const sizeMap = {
  "32": "extra-small",
  "40": "small",
  "48": "medium",
  "64": "large",
} satisfies Record<PillButtonProps["size"], UIPillButtonProps["size"]>;
