import {
  Breadcrumbs,
  DateTimeFormatters,
  Flex,
  Spacer,
  Toggle,
  Typography,
} from "ui";
import { PlaygroundCard } from "../../components/PlaygroundCard/PlaygroundCard";
import { useFlags } from "./hooks/useFlags";
import { openNewWindow } from "src/utils/externalLinks/openNewWindow";
import { useBreadcrumbs } from "./hooks/useBreadcrumbs";

/** Access to feature flags' values */
export function FeatureFlags() {
  const flags = useFlags();
  const breadcrumbs = useBreadcrumbs();

  return (
    <Flex padding={{ all: "m" }} direction="column" align="start" gap="m">
      <Typography.Headline6 color="neutral.10">
        Feature Flags
      </Typography.Headline6>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Flex direction="column" align="start" gap="m">
        {flags.map((flag) => (
          <PlaygroundCard
            key={flag.flag}
            icon={flag.icon}
            title={flag.flag}
            description={flag.description}
            onClick={() => {
              openNewWindow(flag.url);
            }}
          >
            <Toggle
              value={flag.enabled}
              onChange={() => {
                return;
              }}
            />
            <Spacer y="xs3" />
            <Typography.Body4 color="neutral.50">
              No código:{" "}
              <Typography.Headline10 color="neutral.40" as="span">
                {flag.alias}
              </Typography.Headline10>
            </Typography.Body4>
            <Typography.Body4 color="neutral.50">
              Criada em:{" "}
              <Typography.Headline10 color="neutral.40" as="span">
                {DateTimeFormatters.formatDate(flag.createdAt)}, há{" "}
                {DateTimeFormatters.formatDateDistance(flag.createdAt)}
              </Typography.Headline10>
            </Typography.Body4>
          </PlaygroundCard>
        ))}
      </Flex>
    </Flex>
  );
}
