import React from "react";
import { Accordion } from "../Accordion";
import { Color } from "../../../utils/colors";
import { ShapeIcon } from "../../../molecules/ShapeIcon/ShapeIcon";

/** Uses Accordion `props` to calculate its' params */
export function useController(input: React.ComponentProps<typeof Accordion>) {
  return {
    header: {
      descriptionVisible: getDescriptionVisibility(),
      titleColor: getTitleColor(),
    },
    rightActions: getRightActions(),
  } as const;

  /** Calculates title color based on its' props */
  function getTitleColor() {
    if (input.disabled) return "neutral.50" satisfies Color;
    return "neutral.30" satisfies Color;
  }

  /** Returns whether the description should be visible */
  function getDescriptionVisibility() {
    if (input.collapsibleDescription && !input.isOpen) return false;
    return true;
  }

  /** Returns a list of ShapeIcon action buttons */
  function getRightActions() {
    if (input.disabled)
      return [
        {
          children: <ShapeIcon name="IconLock" size={32} variant="neutral" />,
          onClick() {
            return;
          },
        },
      ];
    return [];
  }
}
