import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import { Root } from "./root.component";
import * as external from "../src/external";

const lifeCycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary() {
    // Customize the root error boundary for your micro-front-end here.
    return <div>ERRO</div>;
  },
});

export const { bootstrap, mount, unmount } = lifeCycles;

export { external };
