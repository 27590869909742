import { ThemesType } from "@flash-tecnologia/hros-web-ui-v2";

type Padding = keyof ThemesType["spacings"];

export type ComponentPadding = {
  /** Top padding, overrides `y` on top direction */
  top?: Padding;
  /** Right padding, overrides `x` on right direction */
  right?: Padding;
  /** Bottom padding, overrides `y` on bottom direction */
  bottom?: Padding;
  /** Left padding, overrides `x` on left direction */
  left?: Padding;
  /** Horizontal padding */
  x?: Padding;
  /** Vertical padding */
  y?: Padding;
  /** Padding for all directions, overridden by any other */
  all?: Padding;
};

/** Converts padding shortcuts to css values */
export function padding(theme: ThemesType, padding: ComponentPadding) {
  return `${getPadding(padding.top, padding.y, padding.all)} ${getPadding(
    padding.right,
    padding.x,
    padding.all,
  )} ${getPadding(padding.bottom, padding.y, padding.all)} ${getPadding(
    padding.left,
    padding.x,
    padding.all,
  )}`;

  /** Get padding value for a single direction.
   * @example `* getPadding(top, y)`
   * Will try to use `top` first (if provided), then `y` otherwise. If none are
   * provided, returns `"0"`
   */
  function getPadding(...padding: (Padding | undefined)[]) {
    for (const p of padding) {
      if (p) return theme.spacings[p];
    }
    return "0";
  }
}
