import {
  Auth,
  AuthenticatedUser,
  getAccessToken,
  getAccessTokenPayloadSync,
  getAuthenticatedUser,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";
import React from "react";
import { z } from "zod";

export const AuthService = {
  getAuthorizationToken,
  useCompany,
  useEmployeeId,
  useUser,
};

/** Returns the FlashOS auth JWT */
async function getAuthorizationToken() {
  try {
    const authorization = await getAccessToken();
    return authorization;
  } catch {
    forceSignOut();
    return "";
  }
}

/** Currently selected employeeId */
function useEmployeeId() {
  const accessToken = getAccessTokenPayloadSync();

  if (!accessToken.employeeId) {
    forceSignOut();
    return null;
  }
  return accessToken.employeeId;
}

/** Currently selected company */
function useCompany() {
  const selectedCompany = useSelectedCompany();

  /** Currently selected company */
  const selectedCompanyParsed =
    selectedCompanySchema.safeParse(selectedCompany);

  if (!selectedCompanyParsed.success) {
    forceSignOut();
    return null;
  }

  return selectedCompanyParsed.data.selectedCompany;
}

const selectedCompanySchema = z.object({
  selectedCompany: z.object({
    /** Company id */
    id: z.string(),
    /** Company registration number */
    registrationNumber: z.string(),
  }),
});

/** Currently selected user */
function useUser() {
  const [user, setUser] = React.useState<AuthenticatedUser | undefined>(
    undefined,
  );
  React.useEffect(() => {
    void getAuthenticatedUser().then((user) => {
      setUser(user);
    });
  }, []);

  return user;
}

/** Logs the user out and navigates to login page */
function forceSignOut() {
  // dispatchToast({
  //   content: "Sessão expirada",
  //   type: "error",
  //   description: "Por favor, realize o login novamente.",
  // });
  void Auth.signOut();
}
