import React from "react";
import { RouterOutputs, trpc } from "src/utils/trpc/trpc";
import { showCorporateOnboardingDone } from "../components/CorporateOnboardingDoneModal/CorporateOnboardingDoneModal";

const STEPS_COUNT = 3;

/** Gets the onboarding status for every step */
export function useOnboardingStatus() {
  const status = trpc.corporateOnboarding.getStatus.useQuery(undefined, {
    refetchOnWindowFocus: true,
  });

  const currentStep = getCurrentStep(status.data);
  const progress = currentStep / STEPS_COUNT;
  const isCompleted = currentStep >= OnboardingStep.DOWNLOAD_GUIDES;

  React.useEffect(() => {
    if (currentStep === OnboardingStep.DOWNLOAD_GUIDES) {
      showCorporateOnboardingDone();
    }
  }, [currentStep]);

  return {
    isLoading: status.isLoading,
    currentStep,
    progress,
    isCompleted,
  };
}

/** Calculates the current step based on the onboarding status */
function getCurrentStep(
  status?: RouterOutputs["corporateOnboarding"]["getStatus"],
) {
  if (!status?.success) return OnboardingStep.LOADING;
  if (!status.data.userRegisteredInTeam) return OnboardingStep.REGISTER_TEAM;
  if (!status.data.depositCorporateWallet)
    return OnboardingStep.FLASH_CASH_DEPOSIT;
  if (!status.data.depositMadeToTeam) return OnboardingStep.EMPLOYEE_DEPOSITS;
  if (!status.data.communicateWithYourTeam)
    return OnboardingStep.DOWNLOAD_GUIDES;
  return OnboardingStep.ALL_DONE;
}

export enum OnboardingStep {
  LOADING = -1,
  REGISTER_TEAM = 0,
  FLASH_CASH_DEPOSIT = 1,
  EMPLOYEE_DEPOSITS = 2,
  DOWNLOAD_GUIDES = 3,
  ALL_DONE = 4,
}
