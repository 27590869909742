import React from "react";
import { ThemesType } from "@flash-tecnologia/hros-web-ui-v2";
import { ComponentPadding, padding } from "../../utils/paddings";
import { getColor, type Color } from "../../utils/colors";
import styled from "styled-components";

/** Flex-box `div` component */
export function Flex(props: Props) {
  return <StyledFlex {...props} />;
}

const StyledFlex = styled.div<Props>`
  align-items: ${(p) => p.align ?? "center"};
  background-color: ${(p) =>
    p.backgroundColor ? getColor(p.backgroundColor) : "unset"};
  border-color: ${(p) => (p.borderColor ? getColor(p.borderColor) : "unset")};
  border-style: ${(p) => (p.borderColor ? "solid" : "unset")};
  border-width: ${(p) =>
    p.borderWidth ? p.theme.borders.width[p.borderWidth] : "unset"};
  border-radius: ${(p) => (p.radius ? p.theme.borders.radius[p.radius] : 0)};
  display: flex;
  flex-direction: ${(p) => p.direction ?? "row"};
  flex-grow: ${(p) => p.grow};
  flex-shrink: ${(p) => p.shrink};
  flex-wrap: ${(p) => p.wrap ?? "nowrap"};
  gap: ${(p) => (p.gap ? p.theme.spacings[p.gap] : 0)};
  height: ${(p) => p.height};
  justify-content: ${(p) => p.justify ?? "center"};
  max-height: ${(p) => p.maxHeight};
  max-width: ${(p) => p.maxWidth};
  min-height: ${(p) => p.minHeight};
  min-width: ${(p) => p.minWidth};
  overflow-y: ${(p) => p.overflowY ?? "unset"};
  padding: ${(p) => (p.padding ? padding(p.theme, p.padding) : 0)};
  width: ${(p) => p.width};
`;

type Props = {
  /** align-items */
  align?: "start" | "end" | "center" | "space-around" | "space-between";
  /** background-color */
  backgroundColor?: Color;
  /** border-width */
  borderWidth?: keyof ThemesType["borders"]["width"];
  /** border-color */
  borderColor?: Color;
  /** flex-direction */
  direction?: "column" | "column-reverse" | "row" | "row-reverse";
  /** height */
  height?: `${string}px` | `${string}%`;
  /** justify-content */
  justify?: "start" | "end" | "center" | "space-around" | "space-between";
  /** Gap between children
   * @default 0
   *
   * Options:
   * - xs5: '4px'
   * - xs4: '8px'
   * - xs3: '12px'
   * - xs2: '16px'
   * - xs1: '20px'
   * - xs: '24px'
   * - s: '32px'
   * - m: '40px'
   * - l: '64px'
   * - xl5: '80px'
   * - xl4: '96px'
   * - xl3: '120px'
   * - xl2: '144px'
   * - xl: '160px'
   */
  gap?: keyof ThemesType["spacings"];
  /** flex-grow */
  grow?: number;
  /** max-height */
  maxHeight?: `${string}px` | `${string}%`;
  /** max-width */
  maxWidth?: `${string}px` | `${string}%`;
  /** min-height */
  minHeight?: `${string}px` | `${string}%`;
  /** min-width */
  minWidth?: `${string}px` | `${string}%`;
  /** border-radius */
  radius?: keyof ThemesType["borders"]["radius"];
  /** flex-shrink */
  shrink?: number;
  /** overflow-y */
  overflowY?: "auto" | "hidden" | "scroll" | "visible";
  /** padding */
  padding?: ComponentPadding;
  /** width */
  width?: `${string}px` | `${string}%`;
  /** wrap */
  wrap?: "wrap" | "nowrap" | "wrap-reverse";
  /** Children */
  children: React.ReactNode;
};
