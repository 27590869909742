import React from "react";

/** Wraps a component for lazy loading */
export function LazyComponent<TProps>(props: Props<TProps>) {
  /** Formats the component import for lazy loading with React.lazy */
  async function formatComponentImport() {
    return { default: await props.factory() };
  }

  const Component = React.lazy(formatComponentImport);

  return (
    <React.Suspense fallback={props.fallback ?? null}>
      {/* @ts-expect-error - `innerProps` is a valid prop */}
      <Component {...props.innerProps} />
    </React.Suspense>
  );
}

type Props<TProps> = {
  factory: () => Promise<React.FC<TProps>>;
  fallback?: React.ReactNode;
  innerProps: TProps;
};
