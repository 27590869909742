import React from "react";
import { Divider } from "../../../atoms/Divider/Divider";
import { Flex } from "../../../atoms/Flex/Flex";
import { LinkButton } from "../../../molecules/LinkButton/LinkButton";
import { Grid } from "../../../atoms/Grid/Grid";
import styled from "styled-components";
import { ProgressBar } from "../../../molecules/ProgressBar/ProgressBar";

/** Focused Flow Onboarding Template */
export function FocusedFlowOnboardingTemplate(props: Props) {
  return (
    <Flex direction="column" width="100%" height="100%">
      <Grid
        width="100%"
        padding={{ y: "xs2", x: "l" }}
        columns="1fr auto 1fr"
        gap="xs"
      >
        <Flex justify="start">
          <LinkButton
            leftIcon="IconArrowLeft"
            onClick={props.close}
            variant="neutral"
          >
            Voltar
          </LinkButton>
        </Flex>
        {props.title}
        <Flex justify="end" width="100%">
          <Flex width="200px">
            <ProgressBar progress={props.progress} labelPosition="top" />
          </Flex>
        </Flex>
      </Grid>
      <Divider direction="horizontal" />
      <Flex grow={1} align="start" padding={{ y: "s" }} overflowY="scroll">
        <StyledGrid
          columns="275px 588px 275px"
          justifyContent="center"
          gap="xs"
        >
          <Flex>{props.leftColumn}</Flex>
          <Flex>{props.children}</Flex>
          <Flex>{props.rightColumn}</Flex>
        </StyledGrid>
      </Flex>
      {props.footer && (
        <>
          <Divider direction="horizontal" />
          {props.footer}
        </>
      )}
    </Flex>
  );
}

type Props = {
  title: string;
  children: React.ReactNode;
  close: () => void;
  leftColumn?: React.ReactNode;
  progress: number;
  rightColumn?: React.ReactNode;
  footer?: React.ReactNode;
};

const StyledGrid = styled(Grid)`
  @media screen and (max-width: 1200px) {
    grid-template-columns: min(588px, 90%);
  }
`;
