import { FeatureFlagService } from "src/services/FeatureFlagService";
import { IconName } from "ui";

/** Returns a list of feature flags, with descriptions, current values and links
 * to its Unleash entries
 */
export function useFlags() {
  const corporateCardOnboarding = FeatureFlagService.useFlag(
    "corporateCardOnboarding",
  );
  const requiredDeposit = FeatureFlagService.useFlag("requiredDeposit");

  const flags: FeatureFlag[] = [
    {
      createdAt: new Date("2025-02-18"),
      alias: "corporateCardOnboarding",
      flag: FeatureFlagService.getFeatureFlagName("corporateCardOnboarding"),
      description:
        "Controla a visibilidade de um componente no Dashboard que dá acesso ao guia de onboarding do cartão corporativo",
      icon: "IconCreditCard",
      enabled: corporateCardOnboarding,
      url: FeatureFlagService.getFeatureFlagUrl("corporateCardOnboarding"),
    },
    {
      createdAt: new Date("2025-03-12"),
      alias: "requiredDeposit",
      flag: FeatureFlagService.getFeatureFlagName("requiredDeposit"),
      description:
        "Quando ativa, faz com que a etapa de cadastrar colaboradores no cartão corporativo obrigue o admin. a criar um primeiro depósito para os novos usuários",
      icon: "IconMoneybag",
      enabled: requiredDeposit,
      url: FeatureFlagService.getFeatureFlagUrl("requiredDeposit"),
    },
  ];
  return flags;
}

type FeatureFlag = {
  alias: string;
  createdAt: Date;
  description: string;
  enabled: boolean;
  flag: string;
  icon: IconName;
  url: string;
};
