import { Button, Flex } from "ui";
import { useOnboardingStatus } from "../../hooks/useOnboardingStatus";
import { ModalService } from "src/services/ModalService";
import { useDownloadManuals } from "../../hooks/useDownloadManuals";

/** Footer for the `Corporate Onboarding` Focused Flow */
export function CorporateOnboardingFooter(props: Props) {
  const modalController = ModalService.useModalController();
  const downloadManuals = useDownloadManuals();

  /** OnFinish function */
  function onFinish() {
    modalController.remove();

    if (props.onFinish) props.onFinish();
  }

  if (props.onboardingStatus.isCompleted) {
    return (
      <Flex padding={{ all: "xs3" }} gap="xs">
        <Button
          type="outlined"
          variant="neutral"
          onClick={() => {
            onFinish();
          }}
        >
          Concluir
        </Button>
        <Button onClick={downloadManuals}>Baixar guias de ajuda</Button>
      </Flex>
    );
  }
  return (
    <Flex padding={{ all: "xs3" }}>
      <Button
        type="outlined"
        variant="neutral"
        onClick={() => {
          onFinish();
        }}
      >
        Continuar mais tarde
      </Button>
    </Flex>
  );
}

type Props = {
  onboardingStatus: ReturnType<typeof useOnboardingStatus>;
  onFinish?: () => void;
};
