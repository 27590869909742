import React from "react";
import { ProgressBar } from "../ProgressBar";
import { Color } from "../../../utils/colors";

type ProgressBarProps = React.ComponentProps<typeof ProgressBar>;

/** Uses ProgressBar `props` to calculate its' params */
export function useController(input: ProgressBarProps) {
  const progress = Math.max(0, Math.min(100, Math.round(input.progress * 100)));
  const progressLabel = `${progress.toString()}%${input.labelPosition === "top" ? " concluído" : ""}`;

  return {
    progress,
    progressLabel,
    progressBarColor: getColor(),
  } as const;

  /** Calculates the progress bar color */
  function getColor() {
    if (progress === 100) return "feedback.success.50" as const satisfies Color;

    return "secondary.50" as const satisfies Color;
  }
}
