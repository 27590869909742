import { Color } from "../../../utils/colors";
import { Variant } from "../../../utils/variant";
import React from "react";
import { CircularBackground } from "../CircularBackground";

type CircularBackgroundProps = React.ComponentProps<typeof CircularBackground>;

/** Uses CircularBackground `props` to calculate its' params */
export function useController(input: CircularBackgroundProps) {
  return {
    backgroundColor: getBackgroundColor(),
  };

  /** Calculates background color based on its props */
  function getBackgroundColor() {
    // Disabling overrides the background color
    if (input.disabled) return "neutral.90";

    return backgroundColorMap[input.variant];
  }
}

const backgroundColorMap = {
  default: "secondary.95",
  error: "feedback.error.90",
  info: "feedback.info.90",
  neutral: "neutral.90",
  success: "feedback.success.90",
} as const satisfies Record<Variant, Color>;
