import React from "react";
import { OnboardingStep } from "../../../hooks/useOnboardingStatus";
import { CorporateOnboardingTimeline } from "../CorporateOnboardingTimeline";

/** Controller for the Accordion Timeline */
export function useAccordionTimelineController(input: Input) {
  const currentStep = input.onboardingStatus.currentStep;
  const [accordionOpen, setAccordionOpen] =
    React.useState<null | OnboardingStep>(currentStep);

  React.useEffect(() => {
    setAccordionOpen((prevState) =>
      currentStep && prevState !== currentStep ? currentStep : prevState,
    );
  }, [currentStep]);

  return {
    /** Defines which accordion is open. Only one can be open at a time */
    accordionOpen,
    /** Sets the open accordion */
    setAccordionOpen,
  };
}

type Input = React.ComponentProps<typeof CorporateOnboardingTimeline>;
