import { ModalService } from "src/services/ModalService";
import { Flex, FocusedFlow, PillButton, Typography } from "ui";
import { useOnboardingStatus } from "./hooks/useOnboardingStatus";
import { CorporateOnboardingTimeline } from "./components/CorporateOnboardingTimeline/CorporateOnboardingTimeline";
import { CorporateOnboardingFooter } from "./components/CorporateOnboardingFooter/CorporateOnboardingFooter";

/** Onboarding shown when the corporate contract is signed */
function CorporateOnboardingFlow(props: Props) {
  const modalController = ModalService.useModalController();
  const onboardingStatus = useOnboardingStatus();

  /** Placeholder function */
  function placeholder() {
    return;
  }

  /** OnFinish function */
  function onFinish() {
    modalController.remove();

    if (props.onFinish) props.onFinish();
  }

  return (
    <FocusedFlow.Container>
      <FocusedFlow.Onboarding
        title="Primeiros passos na plataforma"
        close={() => {
          onFinish();
        }}
        progress={onboardingStatus.progress}
        footer={
          <CorporateOnboardingFooter
            onboardingStatus={onboardingStatus}
            onFinish={props.onFinish}
          />
        }
      >
        <Flex direction="column" justify="start" grow={1} gap="xs">
          <Flex justify="space-between" gap="xs2">
            <Flex direction="column" align="start">
              <Typography.Headline8 color="neutral.20">
                Comece a usar Despesas Corporativas
              </Typography.Headline8>
              <Typography.Body4 color="neutral.40">
                Siga os passos a seguir para transformar a maneira de gerenciar
                as despesas da sua empresa!
              </Typography.Body4>
            </Flex>
            <PillButton
              icon={"IconBrandYoutube"}
              iconPosition="left"
              size={32}
              onClick={placeholder}
              variant="default"
            >
              Assistir apresentação
            </PillButton>
          </Flex>
          <CorporateOnboardingTimeline onboardingStatus={onboardingStatus} />
        </Flex>
      </FocusedFlow.Onboarding>
    </FocusedFlow.Container>
  );
}

type Props = {
  onFinish?: () => void;
};

const MODAL_CorporateOnboardingFlow = ModalService.create(
  CorporateOnboardingFlow,
);

/** Opens the Corporate Onboarding Focused Flow */
export function showCorporateOnboardingFlow(props: Props) {
  ModalService.show(MODAL_CorporateOnboardingFlow, props);
}

/** Hides the Corporate Onboarding Focused Flow */
export function hideCorporateOnboardingFlow() {
  ModalService.hide(MODAL_CorporateOnboardingFlow);
}
