import { Route } from "react-router-dom";
import { RouterService } from "src/services/RouterService";
import { Playground } from "./Playground";
import { RouteComponent_FocusedFlows } from "./focused-flows/FocusedFlows";
import { EnvService } from "src/services/EnvService";
import { RouteComponent_FeatureFlags } from "./feature-flags/FeatureFlags";

const playgroundEnabled = EnvService.APP_STAGE !== "PRODUCTION";

export const ROUTE_Playground = RouterService.create(
  "corporate-onboarding/playground",
);

export const RouteComponent_Playground = playgroundEnabled && (
  <>
    <Route index path={ROUTE_Playground.path} element={<Playground />} />
    {RouteComponent_FocusedFlows}
    {RouteComponent_FeatureFlags}
  </>
);
