import { trpc } from "src/utils/trpc/trpc";
import { ExternalRoutes } from "src/routes/external-routes";
import { RouterService } from "src/services/RouterService";
import { external } from "@flash-hros/corporate-card";
import { FeatureFlagService } from "src/services/FeatureFlagService";

/** Returns functions to navigate to/open corporate-card flows */
export function useCorporateFlows() {
  const navigate = RouterService.useNavigate();
  const utils = trpc.useUtils();
  const requiredDeposit = FeatureFlagService.useFlagVariant("requiredDeposit");

  return {
    openActivateUsersFlow,
    openAddFlashCashModal,
    openCardsPage,
    openPoliciesPage,
    openTeamsPage,
    openVirtualCorporateCardsPage,
  };

  /** Opens the `Teams`' management page in a new window */
  function openTeamsPage() {
    navigate({
      route: ExternalRoutes.platform.employees,
      openInNewWindow: true,
      params: {},
      searchParams: {},
      state: {},
    });
  }

  /** Opens the `Activate Users` Focused Flow over this onboarding */
  function openActivateUsersFlow() {
    external.showActivateUsers({
      isOnboardingFlow: true,
      requiredDeposit: requiredDeposit.payload?.value === "true",
      onActionClick() {
        void utils.corporateOnboarding.getStatus.reset();
      },
    });
  }

  /** Opens the `Add Flash Cash` Modal over this onboarding */
  function openAddFlashCashModal() {
    external.showAddFlashCashModal();
  }

  /** Opens the `User Accounts` page in a new window */
  function openVirtualCorporateCardsPage() {
    navigate({
      route: ExternalRoutes.corporateCard.userAccounts,
      openInNewWindow: true,
      params: {},
      searchParams: {},
      state: {},
    });
  }

  /** Opens the `Policies` page in a new window */
  function openPoliciesPage() {
    navigate({
      route: ExternalRoutes.expenseManagement.customizations,
      openInNewWindow: true,
      params: {
        tab: "corporate-card",
      },
      searchParams: {},
      state: {},
    });
  }

  /** Opens the `Cards` page in a new window */
  function openCardsPage() {
    navigate({
      route: ExternalRoutes.platform.cards,
      openInNewWindow: true,
      params: {},
      searchParams: {},
      state: {},
    });
  }
}
