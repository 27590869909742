import { Route } from "react-router-dom";
import { RouterService } from "src/services/RouterService";
import { FeatureFlags } from "./FeatureFlags";

export const ROUTE_FeatureFlags = RouterService.create(
  "corporate-onboarding/playground/feature-flags",
);

export const RouteComponent_FeatureFlags = (
  <Route index path={ROUTE_FeatureFlags.path} element={<FeatureFlags />} />
);
