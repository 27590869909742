import { z } from "zod";
import { RouterService } from "src/services/RouterService";
import { zod } from "react-router-typesafe-routes/zod";

/** Corporate Card routes */
const corporateCard = {
  /** Balances and corporate cards of the employees */
  userAccounts: RouterService.create("corporateCard/user-accounts", {}),
};

/** Expense Management routes */
const expenseManagement = {
  /** Customizations, like Policies */
  customizations: RouterService.create(
    "expenseManagement/customizations?tab=corporate-card",
    {
      searchParams: {
        tab: zod(z.enum(["corporate-card"])).defined(),
      },
    },
  ),
};

/** Platform routes */
const platform = {
  /** Company's plastic cards */
  cards: RouterService.create("cards", {}),
  /** Company's employees */
  employees: RouterService.create("employees", {}),
};

/** Routes from other micro-front-ends */
export const ExternalRoutes = {
  corporateCard,
  expenseManagement,
  platform,
};
