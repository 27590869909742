import React from "react";
import { Dialog, DialogContent } from "@mui/material";
import styled from "styled-components";
import { PillButton } from "../PillButton/PillButton";

/** Base Modal component */
export function Modal(props: Props) {
  return (
    <StyledDialog open={props.isOpen} onClose={props.close}>
      <StyledCloseButtonArea>
        <PillButton
          icon="IconX"
          onClick={props.close}
          size={32}
          variant="neutral"
        />
      </StyledCloseButtonArea>
      <StyledDialogContent size={props.size}>
        {props.children}
      </StyledDialogContent>
    </StyledDialog>
  );
}

type Props = {
  /** Closes the modal */
  close: () => void;
  /** The modal content */
  children: React.ReactNode;
  /** The modal width */
  size: ModalSize;
  /** Whether the modal is open */
  isOpen: boolean;
};

type ModalSize = "sm" | "md" | "lg" | `${string}px`;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: ${({ theme }) => theme.borders.radius.l};
    max-height: ${({ theme }) => `calc(100vh - ${theme.spacings.l})`};
    max-width: fit-content;
  }
`;

const StyledDialogContent = styled(DialogContent)<{ size: ModalSize }>`
  width: ${({ size }) => {
    switch (size) {
      case "sm":
        return "480px";
      case "md":
        return "800px";
      case "lg":
        return "960px";
      default:
        return size;
    }
  }};
  overflow-y: auto;
  padding: 0;
  min-height: 200px;
`;

const StyledCloseButtonArea = styled.div`
  position: absolute;
  top: ${(p) => p.theme.spacings.xs3};
  right: ${(p) => p.theme.spacings.xs3};
`;
