import { trpc } from "src/utils/trpc/trpc";
import { ToastService } from "src/services/ToastService";
import { errors } from "src/utils/texts/errors";

/** Returns a function that skips the flash-cash deposit step */
export function useSkipStep() {
  const trpcUtils = trpc.useUtils();
  const skipStep = trpc.corporateOnboarding.skipStep.useMutation({
    onError: () => {
      ToastService.error({
        title: "Não foi possível pular essa etapa",
        description: errors.actions.toastDescription,
      });
    },
    onSuccess: (data) => {
      if (data.success) {
        trpcUtils.corporateOnboarding.getStatus.setData(undefined, () => {
          return data;
        });
      }
    },
  });

  return {
    skipFlashCashDeposit,
    isLoading: skipStep.isLoading,
  };

  /** As the flash-cash purchase is optional, this step may be skipped */
  function skipFlashCashDeposit() {
    skipStep.mutate({ step: "depositCorporateWallet" });
  }
}
