import { Typography } from "ui";

/** Accordion description for required or optional action */
export function AccordionDescription(props: Props) {
  return (
    <Typography.Body4 color="neutral.40">
      {props.isRequired ? (
        <Typography.Body4 color="secondary.50" as="span">
          Obrigatório
        </Typography.Body4>
      ) : (
        "Recomendado"
      )}
      {" • "}
      {props.children}
    </Typography.Body4>
  );
}

type Props = {
  /** Whether the action is required */
  isRequired?: boolean;
  /** Text rendered alongside the "required or optional" indicator */
  children: string;
};
