import { z } from "zod";

const EnvSchema = z.object({
  APP_STAGE: z.enum(["PRODUCTION", "STAGING", "LOCAL"]),
});

/** Environment variables. Used for secrets */
export const EnvService = EnvSchema.parse({
  APP_STAGE: process.env.APP_STAGE,
});
