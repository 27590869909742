import { RouterService } from "src/services/RouterService";
import { ROUTE_Playground } from "../../..";

/** Returns the breadcrumbs for the Focused Flows page */
export function useBreadcrumbs() {
  const navigate = RouterService.useNavigate();

  return [
    {
      label: "Playground",
      onClick: () => {
        navigate({
          route: ROUTE_Playground,
          params: {},
          searchParams: {},
          state: {},
        });
      },
    },
    {
      label: "Fluxos focados",
    },
  ] as const;
}
