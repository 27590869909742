import React from "react";
import { Flex } from "../../atoms/Flex/Flex";
import { ShapeIcon } from "../../molecules/ShapeIcon/ShapeIcon";
import { Divider } from "../../atoms/Divider/Divider";
import { Accordion } from "../../molecules/Accordion/Accordion";
import { CircularBackground } from "../../atoms/CircularBackground/CircularBackground";
import { Typography } from "../../atoms/Typography/Typography";
import { Grid } from "../../atoms/Grid/Grid";

/** A vertical timeline, that renders a list of accordions */
export function AccordionTimeline(props: Props) {
  return (
    <Flex direction="column">
      {props.steps.map((step, index) => (
        <Item
          accordionProps={step}
          isFirst={index === 0}
          index={index}
          currentStep={props.currentStep}
        />
      ))}
      <End index={props.steps.length} currentStep={props.currentStep} />
    </Flex>
  );
}

type AccordionProps = React.ComponentProps<typeof Accordion>;
type Props = {
  steps: AccordionProps[];
  currentStep: number;
};

/** Renders a single step of the timeline */
function Item(props: ItemProps) {
  const isBlocked = props.index > props.currentStep;
  const isDone = props.index < props.currentStep;
  const isSelected = props.index === props.currentStep;

  return (
    <Grid columns="24px 1fr" width="100%" gap="xs4">
      <Grid rows="24px 24px 1fr">
        <Flex grow={1}>
          {!props.isFirst && <Divider direction="vertical" />}
        </Flex>
        {isDone ? (
          <ShapeIcon name="IconCheck" variant="success" size={24} />
        ) : (
          <CircularBackground
            variant={isSelected ? "default" : "neutral"}
            size={24}
          >
            <Typography.Headline10
              color={isSelected ? "secondary.40" : "neutral.40"}
            >
              {props.index + 1}
            </Typography.Headline10>
          </CircularBackground>
        )}
        <Flex grow={1}>
          <Divider direction="vertical" />
        </Flex>
      </Grid>
      <Flex padding={{ bottom: "xs2" }}>
        <Accordion
          {...props.accordionProps}
          collapsibleDescription
          disabled={isBlocked}
          fullWidth
        />
      </Flex>
    </Grid>
  );
}

type ItemProps = {
  isFirst?: boolean;
  index: number;
  currentStep: number;
  accordionProps: AccordionProps;
};

/** Last component of the timeline */
function End(props: EndProps) {
  const isDone = props.index <= props.currentStep;

  return (
    <Grid columns="24px 1fr" width="100%" gap="xs4">
      <Grid rows="36px 24px">
        <Flex grow={1}>
          <Divider direction="vertical" />
        </Flex>
        <ShapeIcon
          name="IconRocket"
          variant={isDone ? "success" : "neutral"}
          size={24}
        />
      </Grid>
      <Flex justify="start" align="end">
        <Typography.Headline9 color="neutral.50">
          Tudo pronto!
        </Typography.Headline9>
      </Flex>
    </Grid>
  );
}

type EndProps = {
  index: number;
  currentStep: number;
};
