import { Breadcrumbs as UIBreadcrumbs } from "@flash-tecnologia/hros-web-ui-v2";
import { Icon } from "../../atoms/Icon/Icon";
import { Typography } from "../../atoms/Typography/Typography";
import { LinkButton } from "../LinkButton/LinkButton";
import React from "react";

/** Breadcrumbs for representing the hierarchy of a page or content */
export function Breadcrumbs(props: Props) {
  return (
    <UIBreadcrumbs
      breadcrumbs={props.breadcrumbs.map((breadcrumb) =>
        breadcrumb.onClick ? (
          <LinkButton variant="neutral" onClick={breadcrumb.onClick} underline>
            {breadcrumb.label}
          </LinkButton>
        ) : (
          <Typography.Body4 color="neutral.50">
            {breadcrumb.label}
          </Typography.Body4>
        ),
      )}
      separator={<Icon name="IconChevronRight" size={12} variant="neutral" />}
    />
  );
}

type Props = {
  breadcrumbs: readonly {
    label: string;
    onClick?: () => void;
  }[];
};
