import { useNavigate as useReactRouterNavigate } from "react-router-dom";
import {
  InParams,
  InSearchParams,
  InStateParams,
  Route,
  route,
  useTypedParams,
  useTypedSearchParams,
  useTypedState,
} from "react-router-typesafe-routes/dom";

/** Declares a new route
 * @example
 *
 * ```
 * const route = RouterService.create(
 *   '/corporate-onboarding/path/:pathParam',
 *    {
 *      params: {
 *        pathParam: zod(z.string()).defined()
 *      },
 *      state: {
 *        stateParam: zod(z.string()).defined()
 *      },
 *    },
 * )
 * ```
 */
const create = route;

/** Returns the params from the current route */
const useParams = useTypedParams;
/** Returns the search-params from the current route's query string */
const useSearchParams = useTypedSearchParams;
/** Returns the state from the current route */
const useState = useTypedState;

/** Returns a helper function that navigates with type safety. To go back, use -1 */
function useNavigate() {
  const navigate = useReactRouterNavigate();
  return function <
    TPath extends string,
    TPathTypes,
    TSearchTypes,
    THash extends string[],
    TStateTypes,
  >(
    input:
      | NavigateInput<TPath, TPathTypes, TSearchTypes, THash, TStateTypes>
      | -1,
  ) {
    if (input === -1) {
      navigate(-1);
      return;
    }

    const path = input.route.buildPath(input.params, input.searchParams);

    if (input.openInNewWindow) {
      window.open(path, "_blank");
    } else {
      navigate(path, {
        state: input.state,
      });
    }
  };
}

export const RouterService = {
  create,
  useParams,
  useSearchParams,
  useState,
  useNavigate,
};

/* --------------------------------------- Auxiliary types -------------------------------------- */

type NavigateInput<
  TPath extends string,
  TPathTypes,
  TSearchTypes,
  THash extends string[],
  TStateTypes,
> = {
  route: Route<TPath, TPathTypes, TSearchTypes, THash, TStateTypes>;
  params: InParams<TPath, TPathTypes>;
  searchParams: InSearchParams<TSearchTypes>;
  state: InStateParams<TSearchTypes>;
  openInNewWindow?: boolean;
};
