import { DownloadService } from "src/services/DownloadService";
import { useConcludeCommunicationWithYourTeam } from "./useConcludeCommunicateWithYourTeam";

/** Downloads the corporate onboarding manuals */
export function useDownloadManuals() {
  const concludeCommunicationWithYourTeam =
    useConcludeCommunicationWithYourTeam();
  return function downloadManuals() {
    concludeCommunicationWithYourTeam.conclude();
    DownloadService.downloadFile("CORPORATE_ONBOARDING_GUIDES");
  };
}
