import { format, formatDistanceToNow } from "date-fns";
import { ptBR } from "date-fns/locale";

export const DateTimeFormatters = {
  formatDate,
  formatDateDistance,
};

/** Returns a dd/MM/yyyy formatted date */
function formatDate(date: Date) {
  return format(date, "dd/MM/yyyy");
}

/** Returns a formatted distance to now */
function formatDateDistance(date: Date) {
  return formatDistanceToNow(date, { locale: ptBR });
}
