import { DefaultTheme } from "styled-components";
import React from "react";
import { Flex } from "../Flex/Flex";

/** A horizontal or vertical empty space */
export function Spacer(props: Props) {
  return (
    <Flex
      padding={{
        top: props.y,
        right: props.x,
      }}
    >
      {null}
    </Flex>
  );
}

type Props = {
  x?: keyof DefaultTheme["spacings"];
  y?: keyof DefaultTheme["spacings"];
};
