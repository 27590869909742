import { AuthService } from "src/services/AuthService";

/** Assembles HTTP headers */
export function useHeaders() {
  const company = AuthService.useCompany();

  return async () => {
    const authorization = await AuthService.getAuthorizationToken();

    return {
      authorization,
      "company-id": company?.id,
      /** Requests go through a gateway before hitting the BFF. It uses the
       * `x-flash-auth` header for auth.
       */
      "x-flash-auth": `Bearer ${authorization}`,
    };
  };
}
