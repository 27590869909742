import { Toggle as UIToggle } from "@flash-tecnologia/hros-web-ui-v2";
import React from "react";

/** A switch for toggling a value */
export function Toggle(props: Props) {
  return (
    <UIToggle
      disabled={props.disabled}
      checked={props.value}
      onChange={(_e, newValue) => {
        props.onChange(newValue);
      }}
    />
  );
}

type Props = {
  /** Marks the component as disabled */
  disabled?: boolean;
  /** The value of the toggle */
  value: boolean;
  /** Function called when the value changes */
  onChange: (value: boolean) => void;
};
