import AppRouter from "./routes";
import { ThemeProvider } from "@flash-tecnologia/hros-web-ui-v2";
import { TRPCProvider } from "./utils/trpc/trpc";
import NiceModal from "@ebay/nice-modal-react";
import { BrowserRouter } from "react-router-dom";
import { FeatureFlagService } from "./services/FeatureFlagService";

/** App's Root component. Wraps the app with context providers */
export function Root() {
  return (
    <ContextProviders>
      <AppRouter />
    </ContextProviders>
  );
}

/** All of the global context providers */
export function ContextProviders(props: { children: React.ReactNode }) {
  return (
    <BrowserRouter>
      <TRPCProvider>
        <FeatureFlagService.FeatureFlagProvider>
          <ThemeProvider>
            <NiceModal.Provider>{props.children}</NiceModal.Provider>
          </ThemeProvider>
        </FeatureFlagService.FeatureFlagProvider>
      </TRPCProvider>
    </BrowserRouter>
  );
}
