import { Typography } from "../../atoms/Typography/Typography";
import { Flex } from "../../atoms/Flex/Flex";
import React from "react";
import { useController } from "./hooks/useController";

/** A progress bar with a progress label on top */
export function ProgressBar(props: Props) {
  const controller = useController(props);

  return (
    <Flex
      align={props.labelPosition === "top" ? "end" : "center"}
      direction={props.labelPosition === "top" ? "column" : "row-reverse"}
      gap={props.labelPosition === "top" ? undefined : "xs4"}
      width="100%"
    >
      <Typography.Caption color="neutral.40" weight={600}>
        {controller.progressLabel}
      </Typography.Caption>
      <Flex
        backgroundColor="secondary.95"
        justify="start"
        width="100%"
        radius="pill"
      >
        <Flex
          children={null}
          backgroundColor={controller.progressBarColor}
          width={`${controller.progress.toString()}%`}
          height="4px"
          radius="pill"
        />
      </Flex>
    </Flex>
  );
}

type Props = {
  /** Between 0 and 1 */
  progress: number;
  /** Position of the progress label */
  labelPosition: "top" | "right";
};
