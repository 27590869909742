import React from "react";
import { ShapeIcon } from "../ShapeIcon";
import { Icon } from "../../../atoms/Icon/Icon";

type ShapeIconProps = React.ComponentProps<typeof ShapeIcon>;
type IconProps = React.ComponentProps<typeof Icon>;

/** Uses ShapeIcon `props` to calculate its' params (background color, icon size) */
export function useController(input: ShapeIconProps) {
  return {
    iconSize: getIconSize(),
  };

  /** Calculates icon size based on the shape-icon size */
  function getIconSize() {
    return shapeIconSize[input.size];
  }
}

export const shapeIconSize = {
  24: 16,
  32: 16,
  40: 16,
  48: 24,
  56: 32,
  64: 40,
  72: 40,
  96: 64,
} as const satisfies Record<ShapeIconProps["size"], IconProps["size"]>;
