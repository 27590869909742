import React from "react";
import { ThemesType } from "@flash-tecnologia/hros-web-ui-v2";
import { ComponentPadding, padding } from "../../utils/paddings";
import { getColor, type Color } from "../../utils/colors";
import styled from "styled-components";

/** Grid `div` component */
export function Grid(props: Props) {
  return <StyledGrid {...props} />;
}

const StyledGrid = styled.div<Props>`
  align-items: ${(p) => p.align ?? "unset"};
  background-color: ${(p) =>
    p.backgroundColor ? getColor(p.backgroundColor) : "unset"};
  border-color: ${(p) => (p.borderColor ? getColor(p.borderColor) : "unset")};
  border-style: ${(p) => (p.borderColor ? "solid" : "unset")};
  border-width: ${(p) =>
    p.borderWidth ? p.theme.borders.width[p.borderWidth] : "unset"};
  border-radius: ${(p) => (p.radius ? p.theme.borders.radius[p.radius] : 0)};
  display: grid;
  gap: ${(p) => (p.gap ? p.theme.spacings[p.gap] : 0)};
  grid-template-columns: ${(p) => p.columns};
  grid-template-rows: ${(p) => p.rows};
  height: ${(p) => p.height};
  justify-content: ${(p) => p.justifyContent ?? "unset"};
  justify-items: ${(p) => p.justifyItems ?? "unset"};
  padding: ${(p) => (p.padding ? padding(p.theme, p.padding) : 0)};
  width: ${(p) => p.width};
`;

type Props = {
  /** align-items */
  align?: "start" | "end" | "center" | "stretch" | "baseline";
  /** background-color */
  backgroundColor?: Color;
  /** border-width */
  borderWidth?: keyof ThemesType["borders"]["width"];
  /** border-color */
  borderColor?: Color;
  /** grid-template-columns */
  columns?: string;
  /** flex-direction */
  direction?: "row" | "column";
  /** height */
  height?: `${string}px` | `${string}%`;
  /** justify-content */
  justifyContent?: "start" | "end" | "center" | "stretch" | "baseline";
  /** justify-items */
  justifyItems?: "start" | "end" | "center" | "stretch" | "baseline";
  /** Gap between children
   * @default 0
   *
   * Options:
   * - xs5: '4px'
   * - xs4: '8px'
   * - xs3: '12px'
   * - xs2: '16px'
   * - xs1: '20px'
   * - xs: '24px'
   * - s: '32px'
   * - m: '40px'
   * - l: '64px'
   * - xl5: '80px'
   * - xl4: '96px'
   * - xl3: '120px'
   * - xl2: '144px'
   * - xl: '160px'
   */
  gap?: keyof ThemesType["spacings"];
  /** flex-grow */
  grow?: number;
  /** border-radius */
  radius?: keyof ThemesType["borders"]["radius"];
  /** padding */
  padding?: ComponentPadding;
  /** grid-template-rows */
  rows?: string;
  /** width */
  width?: `${string}px` | `${string}%`;
  /** wrap */
  wrap?: "wrap" | "nowrap" | "wrap-reverse";
  /** Children */
  children: React.ReactNode;
};
