import { Flex, IconName, PillButton, ShapeIcon, Spacer, Typography } from "ui";

/** Componente em estilo Card para navegar pelo playground */
export function PlaygroundCard(props: Props) {
  return (
    <Flex
      borderColor="neutral.90"
      borderWidth="xs"
      width="80%"
      radius="s"
      padding={{ all: "xs" }}
      gap="xs"
      align="start"
    >
      <ShapeIcon name={props.icon} size={56} variant="default" />
      <Flex direction="column" grow={1} align="start">
        <Typography.Headline8 color="neutral.40">
          {props.title}
        </Typography.Headline8>
        <Typography.Body4 color="neutral.50">
          {props.description}
        </Typography.Body4>
        <Spacer y="xs1" />
        <PillButton
          onClick={props.onClick}
          icon="IconArrowRight"
          size={32}
          variant="default"
        >
          Acessar
        </PillButton>
        {props.children && <Spacer y="xs1" />}
        {props.children}
      </Flex>
    </Flex>
  );
}

type Props = {
  icon: IconName;
  title: string;
  description: string;
  onClick: () => void;
  children?: React.ReactNode;
};
