import React from "react";
import { Button as UIButton } from "@flash-tecnologia/hros-web-ui-v2";
import { Icon, type IconName } from "../../atoms/Icon/Icon";
import { Variant } from "../../utils/variant";
import { useController } from "./hooks/useController";

/** Action Button */
export function Button(props: Props) {
  const controller = useController(props);

  return (
    <UIButton
      onClick={props.onClick}
      variant={controller.type}
      variantType={props.variant}
    >
      {props.leftIcon && <Icon name={props.leftIcon} size={16} />}
      {props.children}
      {props.rightIcon && <Icon name={props.rightIcon} size={16} />}
    </UIButton>
  );
}

type Props = {
  /** Text rendered as button label */
  children: React.ReactNode;
  /** Color variant. Defaults to `default` */
  variant?: Variant;
  /** Filling type. Defaults to `filled` */
  type?: "filled" | "outlined";
  /** If provided, renders the icon on the label's left */
  leftIcon?: IconName;
  /** Callback executed when the button is clicked */
  onClick: () => void;
  /** If provided, renders the icon on the label's right */
  rightIcon?: IconName;
};
