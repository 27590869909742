type ToastEventDetails = {
  /** Toast title */
  content: string;
  /** Toast description */
  description?: string;
  /** Toast variant */
  type: "error" | "success" | "warning";
};

/** Emits an event that generates a toast */
function dispatchToastEvent(detail: ToastEventDetails) {
  dispatchEvent(new CustomEvent("showToast", { detail }));
}

type ToastInput = {
  /** Toast title */
  title: string;
  /** Toast description */
  description?: string;
};

/** Toast with `error` variant */
function error(input: ToastInput) {
  dispatchToastEvent({
    content: input.title,
    description: input.description,
    type: "error",
  });
}

/** Toast with `success` variant */
function success(input: ToastInput) {
  dispatchToastEvent({
    content: input.title,
    description: input.description,
    type: "success",
  });
}

/** Toast with `warning` variant */
function warning(input: ToastInput) {
  dispatchToastEvent({
    content: input.title,
    description: input.description,
    type: "warning",
  });
}

export const ToastService = {
  error,
  success,
  warning,
};
