import { colors } from "../../../utils/colors";
import { Variant } from "../../../utils/variant";
import React from "react";
import { Icon } from "../Icon";

/** Uses Icon `props` to calculate its' params (color) */
export function useController(input: React.ComponentProps<typeof Icon>) {
  return {
    color: getColor(),
  };

  /** Calculates icon color based on its props */
  function getColor() {
    // Disabling icon overrides the color
    if (input.disabled) return colors.neutral[70];
    // If no variant is provided, returns undefined to inherit color from parent
    if (!input.variant) return undefined;

    return colorMap[input.variant];
  }
}

const colorMap = {
  default: colors.secondary[50],
  error: colors.feedback.error[40],
  info: colors.feedback.info[40],
  neutral: colors.neutral[40],
  success: colors.feedback.success[40],
} as const satisfies Record<Variant, string>;
