import React from "react";
import styled from "styled-components";
import { zIndex } from "../../utils/zIndex";
import { FocusedFlowOnboardingTemplate } from "./templates/Onboarding";

/** Wrapper for Focused Flows, acting as a Modal */
function Container(props: Props) {
  return <StyledFocusedFlow {...props} />;
}

type Props = {
  children: React.ReactNode;
};

const StyledFocusedFlow = styled.div`
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${zIndex.FocusedFlow};
`;

export const FocusedFlow = {
  Container,
  Onboarding: FocusedFlowOnboardingTemplate,
};
