import { Flex, Typography } from "ui";
import { PlaygroundCard } from "./components/PlaygroundCard/PlaygroundCard";
import { RouterService } from "src/services/RouterService";
import { ROUTE_FocusedFlows } from "./focused-flows/FocusedFlows";
import { ROUTE_FeatureFlags } from "./feature-flags/FeatureFlags";

/** A staging-only playground route for testing flows. */
export function Playground() {
  const navigate = RouterService.useNavigate();

  return (
    <Flex padding={{ all: "m" }} direction="column" align="start" gap="m">
      <Typography.Headline6 color="neutral.10">
        Playground do corporate-onboarding
      </Typography.Headline6>
      <Flex direction="column" align="start" gap="xs3" width="100%">
        <PlaygroundCard
          icon="IconArrowsHorizontal"
          title="Fluxos focados"
          description="Acesso direto aos fluxos focados disponíveis neste micro-frontend."
          onClick={() => {
            navigate({
              route: ROUTE_FocusedFlows,
              params: {},
              searchParams: {},
              state: {},
            });
          }}
        />
        <PlaygroundCard
          icon="IconFlag"
          title="Feature Flags"
          description="Valores e descrições das feature flags usadas neste micro-frontend."
          onClick={() => {
            navigate({
              route: ROUTE_FeatureFlags,
              params: {},
              searchParams: {},
              state: {},
            });
          }}
        />
      </Flex>
    </Flex>
  );
}
