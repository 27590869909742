import React from "react";
import { Icon, type IconName } from "../../atoms/Icon/Icon";
import { Variant } from "../../utils/variant";
import { useController } from "./hooks/useController";
import { CircularBackground } from "../../atoms/CircularBackground/CircularBackground";

/** Flash's Icon with a circular colored background */
export function ShapeIcon(props: Props) {
  const controller = useController(props);

  return (
    <CircularBackground
      size={props.size}
      variant={props.variant}
      disabled={props.disabled}
    >
      <Icon
        disabled={props.disabled}
        name={props.name}
        size={controller.iconSize}
        variant={props.variant}
      />
    </CircularBackground>
  );
}

type Props = {
  /** Disables shape-icon, overriding the color */
  disabled?: boolean;
  /** Defines the rendered icon */
  name: IconName;
  /** Defines the shape-icon size in px */
  size: 24 | 32 | 40 | 48 | 56 | 64 | 72 | 96;
  /** Defines the shape-icon color */
  variant: Variant;
};
