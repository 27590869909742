import React from "react";
import { Flex } from "../../atoms/Flex/Flex";
import { ShapeIcon } from "../ShapeIcon/ShapeIcon";

/** A highlighted block of content */
export function Callout(props: Props) {
  return (
    <Flex
      width="100%"
      backgroundColor="neutral.95"
      radius="s"
      padding={{ y: "xs4", x: "xs3" }}
      gap="xs4"
    >
      <Flex shrink={0}>
        <ShapeIcon size={24} name="IconBulb" variant="neutral" />
      </Flex>
      <Flex grow={1} justify="start">
        {props.children}
      </Flex>
    </Flex>
  );
}

type Props = {
  children: React.ReactNode;
};
