import { ModalService } from "src/services/ModalService";
import {
  Button,
  Divider,
  Flex,
  IconName,
  LinkButton,
  List,
  Modal,
  ShapeIcon,
  Spacer,
  Typography,
} from "ui";
import { useDownloadManuals } from "../../hooks/useDownloadManuals";
import { hideCorporateOnboardingFlow } from "../../CorporateOnboardingFlow";
import { useCorporateFlows } from "../../hooks/useCorporateFlows";

/** Shown when the corporate onboarding is completed */
function CorporateOnboardingDone() {
  const modalController = ModalService.useModalController();
  const downloadManuals = useDownloadManuals();
  const corporateFlows = useCorporateFlows();

  /** Closes this modal and the corporate onboarding flow */
  function finishOnboarding() {
    modalController.remove();
    hideCorporateOnboardingFlow();
  }

  return (
    <Modal
      close={modalController.remove}
      isOpen={modalController.visible}
      size="sm"
    >
      <Flex
        direction="column"
        gap="xs3"
        padding={{ y: "xs", x: "m" }}
        align="start"
      >
        <ShapeIcon size={48} name="IconRocket" variant="success" />
        <Typography.Body4 color="feedback.success.40" weight={700}>
          Tudo pronto!
        </Typography.Body4>
        <Typography.Headline8 color="neutral.10">
          Primeiros passos completos! Agora comunique sua equipe
        </Typography.Headline8>
        <Typography.Body4 color="neutral.40">
          Preparamos guias para ajudar vocês a utilizarem o cartão e a
          plataforma da melhor maneira.
        </Typography.Body4>
        <Spacer y="xs5" />
        <Typography.Body4 color="neutral.30" weight={700}>
          Também aproveite para...
        </Typography.Body4>
        <List
          items={[
            <ListItem
              title="Criar cartões virtuais corporativos"
              description="Você também pode criar cartões virtuais para as pessoas"
              icon="IconGiftCard"
              onClick={corporateFlows.openVirtualCorporateCardsPage}
            />,
            <ListItem
              title="Definir políticas para os cartões"
              description="Limite o uso dos cartões corporativos de acordo com as regras da empresa"
              icon="IconShieldCheck"
              onClick={corporateFlows.openPoliciesPage}
            />,
            <ListItem
              title="Pedir cartões físicos"
              description="Peça o cartão Flash para as pessoas que ainda não o tenham"
              icon="IconCreditCard"
              onClick={corporateFlows.openCardsPage}
            />,
          ]}
        />
      </Flex>
      <Divider direction="horizontal" />
      <Flex padding={{ y: "xs3", x: "s" }} gap="xs4">
        <Button onClick={finishOnboarding} type="outlined" variant="neutral">
          Concluir
        </Button>
        <Button onClick={downloadManuals} rightIcon="IconDownload">
          Baixar guias de ajuda
        </Button>
      </Flex>
    </Modal>
  );
}

const MODAL_CorporateOnboardingDone = ModalService.create(
  CorporateOnboardingDone,
);

/** Opens the `Corporate Onboarding Done` Modal */
export function showCorporateOnboardingDone() {
  ModalService.show(MODAL_CorporateOnboardingDone, {});
}

/** Items on a Flat List */
function ListItem(props: ListItemProps) {
  return (
    <Flex padding={{ all: "xs2" }} gap="xs3">
      <ShapeIcon size={24} name="IconRocket" variant="default" />
      <Flex direction="column" align="start">
        <Typography.Headline10 color="neutral.20">
          {props.title}
        </Typography.Headline10>
        <Typography.Caption color="neutral.40">
          {props.description}
        </Typography.Caption>
      </Flex>
      <LinkButton
        onClick={props.onClick}
        rightIcon="IconArrowRight"
        variant="default"
      >
        Acessar
      </LinkButton>
    </Flex>
  );
}

type ListItemProps = {
  icon: IconName;
  title: string;
  description: string;
  onClick: () => void;
};
