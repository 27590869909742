import { LazyComponent } from "ui";

export { showCorporateOnboarding } from "./functions/showCorporateOnboarding";

/** App's Context Providers */
export function ExternalRoot(props: { children: React.ReactNode }) {
  return (
    <LazyComponent
      factory={async () => {
        return (await import("../root.component")).ContextProviders;
      }}
      innerProps={props}
    />
  );
}

/** Exporting the component responsible for onboarding guidance */
export function CorporateOnboardingSection() {
  return (
    <LazyComponent
      factory={async () => {
        return (
          await import(
            "./CorporateCardOnboardingSection/CorporateCardOnboardingSection"
          )
        ).CorporateCardOnboardingSection;
      }}
      innerProps={{}}
    />
  );
}
