import React from "react";
import { Spinner as UISpinner } from "@flash-tecnologia/hros-web-ui-v2";
import { Variant } from "../../utils/variant";
import { useController } from "./hooks/useController";

/** Loading spinner */
export function Spinner(props: Props) {
  const controller = useController(props);
  return <UISpinner size={props.size} variant={controller.variant} />;
}

type Props = {
  size: 16 | 24 | 32 | 48 | 64;
  variant: Exclude<Variant, "neutral">;
};
