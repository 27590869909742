import {
  ColorToken,
  colors as UIColors,
} from "@flash-tecnologia/hros-web-ui-v2";

export const colors = {
  brand: UIColors.brand,
  feedback: {
    error: UIColors.feedback.error,
    info: UIColors.feedback.info,
    success: UIColors.feedback.success,
  },
  neutral: UIColors.neutral,
  secondary: UIColors.secondary,
  status: UIColors.status,
} as const;

export type Color = ColorToken;
export type RGBString = `#${string}`;

/** Maps colors to its RGB values
 * @returns RGB value for the requested color
 */
export function getColor(
  /** Color token */
  color: Color,
) {
  return UIColors.get(color);
}

/** Useful for the storybook control settings */
export const ColorOptions = [
  "flamingo-pink",
  "brand.50",
  "neutral.0",
  "neutral.10",
  "neutral.20",
  "neutral.30",
  "neutral.40",
  "neutral.50",
  "neutral.60",
  "neutral.70",
  "neutral.80",
  "neutral.90",
  "neutral.95",
  "neutral.100",
  "secondary.10",
  "secondary.20",
  "secondary.30",
  "secondary.40",
  "secondary.50",
  "secondary.70",
  "secondary.80",
  "secondary.90",
  "secondary.95",
  "secondary.99",
  "tertiary.40",
  "tertiary.70",
  "tertiary.80",
  "tertiary.90",
  "feedback.success.10",
  "feedback.success.40",
  "feedback.success.50",
  "feedback.success.70",
  "feedback.success.90",
  "feedback.error.10",
  "feedback.error.40",
  "feedback.error.50",
  "feedback.error.70",
  "feedback.error.90",
  "feedback.info.10",
  "feedback.info.40",
  "feedback.info.50",
  "feedback.info.70",
  "feedback.info.90",
  "status.attention.10",
  "status.attention.40",
  "status.attention.50",
  "status.attention.70",
  "status.attention.90",
  "status.complete.10",
  "status.complete.40",
  "status.complete.50",
  "status.complete.70",
  "status.complete.90",
  "status.inactive.10",
  "status.inactive.40",
  "status.inactive.50",
  "status.inactive.70",
  "status.inactive.90",
  "status.negative.10",
  "status.negative.40",
  "status.negative.50",
  "status.negative.70",
  "status.negative.90",
  "status.pending.10",
  "status.pending.40",
  "status.pending.50",
  "status.pending.70",
  "status.pending.90",
  "status.progress.10",
  "status.progress.40",
  "status.progress.50",
  "status.progress.70",
  "status.progress.90",
] as const satisfies readonly ColorToken[];
