import { Breadcrumbs, Flex, Typography } from "ui";
import { PlaygroundCard } from "../../components/PlaygroundCard/PlaygroundCard";
import { showCorporateOnboardingFlow } from "src/pages/flows/CorporateOnboardingFlow/CorporateOnboardingFlow";
import { useBreadcrumbs } from "./hooks/useBreadcrumbs";

/** Acesso aos fluxos focados do micro-frontend */
export function FocusedFlows() {
  const breadcrumbs = useBreadcrumbs();
  return (
    <Flex padding={{ all: "m" }} direction="column" align="start" gap="m">
      <Typography.Headline6 color="neutral.10">
        Fluxos Focados
      </Typography.Headline6>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <PlaygroundCard
        icon="IconCreditCard"
        title="Onboarding de cartão corporativo"
        description="Primeiros passos para o uso do cartão corporativo, incluindo o cadastro de colaboradores, primeira compra de flash-cash corporativa e primeiros depósitos de saldo."
        onClick={() => {
          showCorporateOnboardingFlow({});
        }}
      />
    </Flex>
  );
}
