import {
  AccordionTimeline,
  Button,
  Callout,
  Flex,
  LinkButton,
  Spinner,
  Typography,
} from "ui";
import { useCorporateFlows } from "../../hooks/useCorporateFlows";
import {
  OnboardingStep,
  useOnboardingStatus,
} from "../../hooks/useOnboardingStatus";
import { useSkipStep } from "../../hooks/useSkipFlashCashDeposit";
import { external } from "@flash-hros/corporate-card";
import { AccordionDescription } from "../AccordionDescription/AccordionDescription";
import { FeatureFlagService } from "src/services/FeatureFlagService";
import { useAccordionTimelineController } from "./hooks/useAccordionTimelineController";

/** Timeline for a corporate onboarding, composed of accordions */
export function CorporateOnboardingTimeline(props: Props) {
  const accordionTimelineController = useAccordionTimelineController(props);
  const corporateFlows = useCorporateFlows();
  const skipStep = useSkipStep();
  const requiredDeposit = FeatureFlagService.useFlagVariant("requiredDeposit");

  if (props.onboardingStatus.isLoading)
    return (
      <Flex width="100%" height="100%">
        <Spinner variant={"default"} size={64} />
      </Flex>
    );

  return (
    <AccordionTimeline
      currentStep={props.onboardingStatus.currentStep}
      steps={[
        {
          title: "Cadastre sua equipe",
          isOpen:
            accordionTimelineController.accordionOpen ===
            OnboardingStep.REGISTER_TEAM,
          onToggle: (isOpen) => {
            accordionTimelineController.setAccordionOpen(
              isOpen ? OnboardingStep.REGISTER_TEAM : null,
            );
          },
          description: (
            <AccordionDescription isRequired>
              Até 5 minutos
            </AccordionDescription>
          ),
          children: (
            <Flex direction="column" gap="xs2">
              <Typography.Body4 color="neutral.30">
                Cadastre sua equipe e envie convites para que acessem o app e a
                plataforma Flash.
              </Typography.Body4>
              <Callout>
                <Typography.Caption color="neutral.30">
                  Você pode realizar esta etapa inicialmente para sua
                  habilitação e, após se familiarizar com o produto, liberar o
                  acesso para sua equipe.
                </Typography.Caption>
              </Callout>
              <Button
                leftIcon="IconUsers"
                onClick={corporateFlows.openTeamsPage}
              >
                Cadastrar equipe
              </Button>
            </Flex>
          ),
        },
        {
          title: "Deposite em sua carteira corporativa",
          isOpen:
            accordionTimelineController.accordionOpen ===
            OnboardingStep.FLASH_CASH_DEPOSIT,
          onToggle: (isOpen) => {
            accordionTimelineController.setAccordionOpen(
              isOpen ? OnboardingStep.FLASH_CASH_DEPOSIT : null,
            );
          },
          description: (
            <AccordionDescription>Até 2 minutos</AccordionDescription>
          ),
          children: (
            <Flex direction="column" gap="xs2">
              <Typography.Body4 color="neutral.30">
                Use a carteira corporativa para distribuir saldo a qualquer
                momento para as pessoas que utilizarão o cartão corporativo.
              </Typography.Body4>
              <Callout>
                <Typography.Caption color="neutral.30">
                  Este passo é opcional, mas sugerimos que o faça para
                  simplificar a sua gestão de saldo na plataforma.
                </Typography.Caption>
              </Callout>
              <Flex gap="xs2" justify="start" width="100%">
                <external.ExternalRoot>
                  <Button
                    leftIcon="IconWallet"
                    onClick={corporateFlows.openAddFlashCashModal}
                  >
                    Adicionar saldo à carteira
                  </Button>
                </external.ExternalRoot>
                {props.onboardingStatus.currentStep <=
                  OnboardingStep.FLASH_CASH_DEPOSIT && (
                  <LinkButton
                    leftIcon="IconCheck"
                    onClick={skipStep.skipFlashCashDeposit}
                    variant="neutral"
                    disabled={skipStep.isLoading}
                  >
                    Marcar como concluído
                  </LinkButton>
                )}
              </Flex>
            </Flex>
          ),
        },
        {
          title: "Deposite para as pessoas da equipe",
          isOpen:
            accordionTimelineController.accordionOpen ===
            OnboardingStep.EMPLOYEE_DEPOSITS,
          onToggle: (isOpen) => {
            accordionTimelineController.setAccordionOpen(
              isOpen ? OnboardingStep.EMPLOYEE_DEPOSITS : null,
            );
          },
          description: (
            <AccordionDescription
              isRequired={requiredDeposit.payload?.value === "true"}
            >
              Até 2 minutos
            </AccordionDescription>
          ),
          children: (
            <Flex direction="column" gap="xs2">
              <Typography.Body4 color="neutral.30">
                Selecione quem utilizará o cartão corporativo e deposite um
                valor inicial para que elas já possam acessar e conhecer as
                funcionalidades do cartão.
              </Typography.Body4>
              <Callout>
                <Typography.Caption color="neutral.30">
                  Você poderá complementar este valor sempre que necessário.
                </Typography.Caption>
              </Callout>
              <external.ExternalRoot>
                <Button
                  leftIcon="IconClick"
                  onClick={corporateFlows.openActivateUsersFlow}
                >
                  Ativar uso corporativo
                </Button>
              </external.ExternalRoot>
            </Flex>
          ),
        },
      ]}
    />
  );
}

type Props = {
  onboardingStatus: ReturnType<typeof useOnboardingStatus>;
};
