import React from "react";
import { Button } from "../Button";

/** Uses Button `props` to calculate its' params */
export function useController(input: React.ComponentProps<typeof Button>) {
  return {
    type: getType(),
  } as const;

  /** Calculates the button filling type */
  function getType() {
    const type = input.type ?? "filled";
    if (type === "filled") return "primary";
    return "secondary";
  }
}
