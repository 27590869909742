import * as featureFlag from "@flash-tecnologia/feature-flags";
import { AuthService } from "./AuthService";
import { EnvService } from "./EnvService";

export const FeatureFlagService = {
  FeatureFlagProvider,
  getFeatureFlagName,
  getFeatureFlagUrl,
  useFlag,
  useFlagVariant,
};

/** Context provider for the root component */
function FeatureFlagProvider(props: { children: React.ReactNode }) {
  const company = AuthService.useCompany();
  const companyNanoId = company?.id ?? "";
  const employeeNanoId = AuthService.useEmployeeId() ?? "";
  const unleashSetup = unleashSetupMap[EnvService.APP_STAGE];

  return (
    <featureFlag.FlagsProvider
      appName={`flash_os&environment=${EnvService.APP_STAGE}`}
      token={unleashSetup.token}
      url={unleashSetup.url}
      refreshIntervalSeconds={1800}
      initialContext={{
        userId: employeeNanoId,
        properties: {
          employeeNanoId,
          companyNanoId,
          environment: EnvService.APP_STAGE,
        },
      }}
    >
      {props.children}
    </featureFlag.FlagsProvider>
  );
}

/** Gets the feature flag name on unleash */
function getFeatureFlagName(flag: FeatureFlag) {
  return FEATURES[flag];
}

/** Gets the feature flag URL on unleash */
function getFeatureFlagUrl(flag: FeatureFlag) {
  return `https://unleash-benefits.us.private.flashapp.services/projects/default/features/${FEATURES[flag]}`;
}

/** Gets the feature flag value */
function useFlag(flag: FeatureFlag) {
  featureFlag.WithFlag({
    flagName: FEATURES[flag],
  });
  return featureFlag.useFlag({
    flagName: FEATURES[flag],
  });
}

/** Gets the feature flag value with variant */
function useFlagVariant(flag: FeatureFlag) {
  featureFlag.WithFlag({
    flagName: FEATURES[flag],
    variant: true,
  });
  return featureFlag.useFlag({
    flagName: FEATURES[flag],
    variant: true,
  });
}

const FEATURES = {
  /** Enables the corporate card onboarding visibility */
  corporateCardOnboarding: "FLASH_OS_CORPORATE_CARD_ONBOARDING",
  /** For the corporate card onboarding, sets the first deposit as required when
   * creating new corporate users
   */
  requiredDeposit: "FLASHOS_CORPORATE_CARD_REQUIRED_DEPOSIT",
};
type FeatureFlag = keyof typeof FEATURES;

const unleashSetupMap = {
  LOCAL: {
    url: "https://unleash-benefits-proxy-development.us.flashapp.services/proxy",
    token: "proxy-dev",
  },
  STAGING: {
    url: "https://unleash-benefits-proxy-development.us.flashapp.services/proxy",
    token: "proxy-dev",
  },
  PRODUCTION: {
    url: "https://unleash-benefits-proxy-production.us.flashapp.services/proxy",
    token: "proxy-prod",
  },
} as const;
