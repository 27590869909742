import React from "react";
import { PillButton as UIPillButton } from "@flash-tecnologia/hros-web-ui-v2";
import { type IconName } from "../../atoms/Icon/Icon";
import { Variant } from "../../utils/variant";
import { useController } from "./hooks/useController";

/** May be used as a circular icon button or a pill button (when a label is provided) */
export function PillButton(props: Props) {
  const controller = useController(props);

  return (
    <UIPillButton
      icon={props.icon}
      iconPosition={props.iconPosition}
      label={props.children}
      loading={props.isLoading}
      onClick={props.onClick}
      size={controller.size}
      variant={controller.variant}
      type={controller.type}
    />
  );
}

type Props = {
  /** Used as a label */
  children?: string;
  /** Icon name */
  icon: IconName;
  /** Icon position (useful when a label is provided) */
  iconPosition?: "left" | "right";
  /** Replaces the icon with a loading spinner */
  isLoading?: boolean;
  /** Called when the button is clicked */
  onClick: () => void;
  /** Button filling type. @default "outlined" */
  type?: "filled" | "outlined";
  /** Button height in pixels */
  size: 32 | 40 | 48 | 64;
  /** Button variant */
  variant: Variant;
};
