import React from "react";
import { Flex } from "../Flex/Flex";
import { Variant } from "../../utils/variant";
import { useController } from "./hooks/useController";

/** Circular colored background */
export function CircularBackground(props: Props) {
  const controller = useController(props);

  return (
    <Flex
      backgroundColor={controller.backgroundColor}
      height={`${props.size.toString()}px`}
      radius="circular"
      width={`${props.size.toString()}px`}
      shrink={0}
    >
      {props.children}
    </Flex>
  );
}

type Props = {
  /** Rendered inside the circular background */
  children: React.ReactNode;
  /** Disables shape-icon, overriding the color */
  disabled?: boolean;
  /** Defines the shape-icon size in px */
  size: 24 | 32 | 40 | 48 | 56 | 64 | 72 | 96;
  /** Defines the shape-icon color */
  variant: Variant;
};
