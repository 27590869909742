export const DownloadService = {
  downloadFile,
};

/** Starts the download of a file in a new tab */
function downloadFile(file: DownloadableFile) {
  window.open(downloadableFiles[file]);
}

const downloadableFiles = {
  CORPORATE_ONBOARDING_GUIDES:
    "https://flashapp-public-assets.s3.sa-east-1.amazonaws.com/corporate-card/corporate-onboarding/Carta%CC%83o+Corporativo+-+Guias.zip",
} as const;

type DownloadableFile = keyof typeof downloadableFiles;
