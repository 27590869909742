import React from "react";
import { LinkButton as UILinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import { Icon, type IconName } from "../../atoms/Icon/Icon";
import { Variant } from "../../utils/variant";

/** Link Button, underlined or not */
export function LinkButton(props: Props) {
  return (
    <UILinkButton
      disabled={props.disabled}
      onClick={props.onClick}
      underlined={Boolean(props.underline)}
      variant={props.variant}
    >
      {props.leftIcon && <Icon name={props.leftIcon} size={16} />}
      {props.children}
      {props.rightIcon && <Icon name={props.rightIcon} size={16} />}
    </UILinkButton>
  );
}

type Props = {
  /** Text rendered as button label */
  children: React.ReactNode;
  /** Disables interaction */
  disabled?: boolean;
  /** Color variant. */
  variant: Variant;
  /** If provided, renders the icon on the label's left */
  leftIcon?: IconName;
  /** Callback executed when the button is clicked */
  onClick: () => void;
  /** If provided, renders the icon on the label's right */
  rightIcon?: IconName;
  /** If true, renders an underline */
  underline?: boolean;
};
